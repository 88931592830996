.table-responsive2 {
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #e0e0e0;
  /* For Firefox */
}

.table-responsive2::-webkit-scrollbar {
  height: 8px;
  /* Height of horizontal scrollbar */
}

.table-responsive2::-webkit-scrollbar-track {
  background: #e0e0e0;
  /* Track color */
}

.table-responsive2::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Scrollbar color */
  border-radius: 10px;
  /* Roundness of the scrollbar */
  border: 2px solid #e0e0e0;
  /* Space around scrollbar */
}

.table-responsive2::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color on hover */
}

.table1 td, .table1 th {
  padding: 13px;
  background-color: #e0e0e0;
  text-align: center;
  border: 1px solid black;
}
.table1 td{
    background-color: whitesmoke;
}

.noScrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
}

.noScrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}
.table2 td, .table2 th {
  padding: 4px;
  background-color: #e0e0e0;
  text-align: center;
  border: 1px solid black;
}
.table2 td{
    background-color: whitesmoke;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  /* /* font-style: normal; */
  font-size: 14px;
}
.navbar-input {
  width: 40%;
  outline: black;
}
.profile-img {
  object-fit: cover;
  object-position: center;
}
.sidebar {
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 6;
  transition: 0.4s;
}

.leads-sidebar {
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
}

.sidebar-color {
  background: rgb(37, 15, 60);
  background: linear-gradient(
    90deg,
    rgba(37, 15, 60, 1) 0%,
    rgba(87, 48, 156, 1) 100%
  );
}
.navbar-subdiv {
  width: 35%;
}

.student-login {
  background-image: url('../src/images/student/Student\ Login\ page\ \(1\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.trainer-login {
  background-image: url('../src/images/student/Trainer\ Login\ page.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.emp-cards {
  min-height: 100px;
  cursor: pointer;
  border-radius: 0;
  background-color: #e8e8e8;
  color: #202224;
}

.seleceted_empcard {
  min-height: 100px;
  cursor: pointer;
  border-radius: 0;
  background-color: #002147;
  color: white;
}

.emp_profile_layout p:nth-child(1) {
  width: 44%;
}
.emp_profile_layout select,
.emp_profile_layout p:nth-child(2) {
  width: 56%;
}

.date-column {
  width: 100px; /* Adjust the width as needed */
}

.filter {
  width: 40%;
}
.dashboard-profile {
  /* background-image: url('/src/images/Frame\ 3.png'); */
  background-position: center;
  background-size: cover;
}
.required-field {
  color: red;
}

.login-image {
  background-image: url('../src/images/Dashboard.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.login-input::placeholder {
  color: #250f3c;
  font-weight: 500;
}

.form-inputs,
.form-control,
.form-select {
  background-color: rgba(0, 33, 71, 0.15);
  border-radius: 0;
}
label {
  color: #667a91;
  font-size: 15px;
}
.form-control::placeholder {
  color: #002147;
  font-size: 14px;
  font-weight: 500;
}
.form-select {
  cursor: pointer;
}
.form-heading {
  color: #000435;
  font-size: 16px;
}

.lead-dashboard {
  position: fixed;
  top: 50px;
  left: 80px;
  width: 160px;
}

.login-div {
  background-image: url('../src/images/Frame\ 369\ \(1\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

td p {
  width: 140px;
  word-break: break-all;
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .profile-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-tag-div {
    width: 100%;
  }
  .profile-container {
    display: flex;
    justify-content: center;
  }
  .dashboard-profile {
    width: 84%;
  }
  .add-details {
    overflow-x: scroll;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .add-details {
    overflow-x: scroll;
  }

  .profile-tag-div {
    width: 40%;
  }
  .profile-div {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .profile-container {
    display: flex;
    justify-content: end;
  }
  .dashboard-profile {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .login-form {
    width: 70%;
  }
  .leads-div {
    margin-left: 160px;
  }
  .dash-card {
    width: 85%;
    height: 400px;
  }
  .login-header {
    font-size: 50px;
    letter-spacing: 3px;
  }
  .dashboard-profile {
    width: 60%;
    height: 110px;
  }
  .main-div {
    /* margin-left: 230px; */
  }
  .profile-tag-div {
    width: 35%;
  }
  .nav-div1 {
    width: 75%;
  }
  .nav-div2 {
    width: 25%;
  }
  .student-maincontainer {
    margin-left: 220px;
    margin-top: 70px;
  }

  .main-container {
    margin-left: 80px;
    margin-top: 50px;
  }
  .main-container-2 {
    margin-left: 60px;
  }
  .main-container-3 {
    margin-left: 82px;
  }
  .profile-div {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .profile-container {
    display: flex;
    justify-content: end;
  }
  .invoice_div {
    width: 98%;
  }
  /* Invoice */

  .invoice-text {
    font-size: 16px;
  }
  .font1 {
    font-size: 14px;
    width: 200px;
  }
  .invoce-para1 {
    width: 40%;
  }
  .invoce-para2 {
    width: 60%;
  }

  .dashboard-div {
    height: 175px;
  }

  .ask-doubt-div {
    min-height: 530px;
  }
  .complaint-div {
    width: 50%;
  }
  .complaint-div2 {
    width: 60%;
    /* height: 400px; */
  }
  .leave-div {
    width: 60%;
  }
  .feedback-head {
    font-size: 110px;
    font-weight: 900;
    letter-spacing: 10px;
    color: #e0e0f0;
  }
  .student-login-div2 {
    width: 86%;
  }
}

@media screen and (min-width: 1260px) {
  .sticky-col {
    position: fixed;
    right: 0;
    top: 70px;
    height: 100vh;
    width: 377px;
  }
}

.offcanvas {
  transition-duration: 0.5s; /* Adjust the duration as needed (e.g., 0.5s for half a second) */
}

.navbar-input1::placeholder {
  color: #8695a0;
  font-size: 16px;
}
.navbar-link-text {
  font-size: 11.5px;
  display: block;
  text-align: center;
  margin-top: 4px;
}
.navbar-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;
}

/* Certificate */
.certificate1 {
  background-image: url('/src/images/certificate/Group\ 6.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.certificate2 {
  background-image: url('/src/images/certificate/Group\ 7.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.certificate1-head {
  font-family: 'Merriweather', serif;
  font-size: 70px;
  letter-spacing: 18px;
  color: #c9a448;
}
.certificate1-head2 {
  font-family: 'Merriweather', serif;
  font-size: 40px;
  letter-spacing: 1px;
  color: #1d1d5a;
}
.certificate1-text {
  font-family: sans-serif;
  font-size: 20px;
  letter-spacing: 4px;
}

.cetrificate2-1 {
  /* background-image: url('/src/images/vec.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.cetrificate2-2 {
  /* background-image: url('/src/images/1.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.certificate2-head2 {
  font-family: 'Tangerine', cursive;
  font-style: italic;
  font-size: 70px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #414a55;
}
.certificate2-para {
  font-family: 'DM Serif Text', serif;
  color: lightslategray;
  font-weight: 400;
  font-style: normal;
}

.content-wrapper {
  display: flex;
}

.scrollable {
  overflow-y: auto;
}

.sticky-col > p {
  font-family: 'Tangerine', cursive;
  font-style: italic;
  font-size: 38px;
}

.table-row {
  border-bottom: 1px solid lightgray;
  /* width: 160px; */
}

/* Invoice */
.invoice-table {
  background: rgb(40, 17, 66);
  background: linear-gradient(
    90deg,
    rgba(40, 17, 66, 1) 0%,
    rgba(84, 46, 150, 1) 50%,
    rgba(41, 18, 69, 1) 100%
  );
  color: white;
}
.invoice-table-cell {
  background-color: #d3d2d7;
  padding: 20px;
  border-right: 2px solid #c5c5c9;
}
.custom-header {
  background-color: #d4edda; /* Custom background color for header */
}

.profile-div {
  /* background-image: url('./images/mario-gogh-VBLHICVh-lI-unsplash.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
}
.social-icons {
  background-color: #eef3f2;
  color: #26695c;
  transition: 0.6s;
  cursor: pointer;
}
.social-icons:hover {
  background-color: #26695c;
  color: white;
}

.navbaroffcanvas {
  transition: 0.5s ease-in-out;
}

.word-break {
  overflow-wrap: break-word;
}

.sidebar-valid {
  color: white;
  background: transparent;
  border-bottom: 1px solid #1d1854;
}
.sidebar-valid2 {
  color: white;
  background-color: rgba(0, 119, 255, 0.4);
  border: none;
}

input[type='file'] {
  display: none;
}

.custom-file-input {
  display: inline-block;
  /* padding: 10px 15px; */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: 2px solid #3498db;
  color: #3498db;
  border-radius: 5px;
  background-color: white;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the vertical scrollbar */
  height: 8px; /* Adjust the height of the horizontal scrollbar */
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: lightgrey;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 2px; /* Adjust the border-radius to match the reduced width */
  cursor: pointer;
}

/* Style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}

/* Student Panel */
.dashboard-div {
  background-image: url('../src/images/Rectangle\ 5.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.dash-subdiv {
  background-color: white;
}

.join-btn {
  background: rgb(37, 15, 60);
  background: linear-gradient(
    141deg,
    rgba(37, 15, 60, 1) 0%,
    rgba(87, 48, 156, 1) 100%
  );
}

.add-option::placeholder {
  font-size: 12px;
}
.activity::placeholder {
  color: #464a4d;
}

.sticky2 {
  position: fixed;
  top: 0px;
  width: 886px;
  z-index: 1000;
}

.change-color {
  color: #250f3c;
  border-bottom: 2px solid #250f3c;
}

.ask-doubt-div {
  background: rgb(178, 235, 242);
  background: linear-gradient(
    180deg,
    rgba(178, 235, 242, 1) 0%,
    rgba(209, 196, 233, 1) 100%
  );
}

/* Projects */

.project-main-div {
  background-image: url('../src/images/Group\ 48095863.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.project-nav {
  /* background-image: url('../src/images/project/Group\ 48095844.png'); */
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: inline-block;
  font-size: 24px;
}

.sub-div {
  background-image: url('../src/images/Group\ 48095840.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 168px;
  border-radius: 10px;
}

.projects {
  border-top: 6px solid #1f87ff;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid rgb(104, 103, 103);
}

.projects2 {
  border-top: 6px solid #1f87ff;
}

.sub-div2 {
  background-image: url('../src/images/Group\ 48095840.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-dash {
  /* background-image: url('../src/images/Group\ 48095804.png'); */
  background-position: center;
  background-repeat: repeat-y;
  /* background-size: cover; */
}

.project-view {
  background: rgb(63, 39, 102);
  background: linear-gradient(
    90deg,
    rgba(63, 39, 102, 1) 0%,
    rgba(54, 53, 103, 1) 100%
  );
}

.class1 {
  background-color: #57309c;
}
.class2 {
  color: #57309c;
}
.border2 {
  border: 1px solid #57309c;
}

.view-btns {
  cursor: pointer;
  background: rgb(49, 48, 54);
  background: linear-gradient(
    360deg,
    rgba(49, 48, 54, 1) 30%,
    rgba(121, 117, 131, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  );
}

.btns {
  background-color: #002147;
  padding: 10px;
  border-radius: 0;
  border: 0;
}
.text-color {
  color: #002147;
}

.view-selected-btn {
  cursor: pointer;
  background: rgb(24, 200, 255);
  background: linear-gradient(
    230deg,
    rgba(24, 200, 255, 1) 0%,
    rgba(147, 63, 254, 1) 100%
  );
}

.view-project {
  /* background-image: url('../src/images/card2.png'); */
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
}

.nav-btn {
  background: rgb(24, 200, 255);
  background: linear-gradient(
    200deg,
    rgba(24, 200, 255, 1) 0%,
    rgba(147, 63, 254, 1) 100%
  );
}

/* ADMIN */
.dash_data {
  color: #1b2559;
  font-size: 22px;
}
.dash_data1 {
  color: #a3aed0;
  font-size: 13px;
}

.para1 {
  background-color: #57309c;
  font-size: 12px;
  border: 1px solid #57309c;
  color: white;
}
.para2 {
  background-color: #ffff;
  font-size: 12px;
  border: 1px solid #57309c;
  color: #57309c;
}

.dash {
  height: 110px;
}

/* Trainer */
.trainer-dashboard-div {
  height: 175px;
  background-image: url('../src/images/student/Frame\ 13.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* PROJECT */
.view-cat {
  background: rgb(29, 0, 101);
  background: linear-gradient(
    180deg,
    rgba(29, 0, 101, 1) 0%,
    rgba(41, 0, 145, 1) 100%
  );
}
.project-input::placeholder {
  color: white;
  font-size: 13px;
}
.project-head {
  color: rgb(240, 159, 253);
  color: linear-gradient(
    90deg,
    rgba(240, 159, 253, 1) 36%,
    rgba(20, 148, 241, 1) 100%
  );
  font-size: 30px;
  font-weight: 600;
}

.lead_details_div p {
  display: flex;
  border-bottom: 1px solid lightgray;
  color: black;
  font-weight: 600;
  padding-bottom: 1px;
}
.lead_details_div span {
  display: block;
  width: 40%;
  color: #acacac;
}

@keyframes scrollRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-300%);
  }
}

.tw-scrolling-text {
  display: inline-block;
  white-space: nowrap;
  animation: scrollRightToLeft 15s linear infinite;
}

@keyframes blinkRed {
  0%,
  100% {
    background-color: white;
  }
  50% {
    background-color: red;
  }
}

.blinking {
  animation: blinkRed 1s infinite;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.default-cell {
  z-index: 5;
}

/* .default-header {
  z-index: 50;
  position: relative;
} */

/* Custom Tooltip Styles */
.custom-tooltip {
  background-color: #f9f9f9;
  color: #333;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  max-width: 300px;
  word-break: break-word;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.gradient-background::before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 70, 237, 0.5) 0%,
      rgba(139, 85, 255, 0.9) 100%
    ),
    url('../src/Templates/Matrix.png');
  background-size: cover;
  background-position: center;
  opacity: 1; /* Adjust gradient visibility */
}

.gradient-background > * {
  position: relative;
  z-index: 10;
}

.walkin-history {
  max-height: 24rem; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

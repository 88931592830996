.grdtemp {
  background: rgb(255,70,237);
  background: linear-gradient(130deg, rgba(255,70,237,1) 0%, rgba(139,85,255,1) 100%);
}
.table-responsive {
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #e0e0e0;
  /* For Firefox */
}

.table-responsive::-webkit-scrollbar {
  height: 8px;
  /* Height of horizontal scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
  background: #e0e0e0;
  /* Track color */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Scrollbar color */
  border-radius: 10px;
  /* Roundness of the scrollbar */
  border: 2px solid #e0e0e0;
  /* Space around scrollbar */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color on hover */
}

